<template>
  <div class="card form-rounded-inputs card-ecart">
    <div class="card-header">
      <h5 v-text="  $t('dashboard.stores.title') "> </h5>
    </div>
    <div class=" m-t-50 m-b-50 text-center card-body">
      <div>
        <div v-if=" storeList.length == 0">
          <h4 class="f-w-700" v-text=" $t('dashboard.stores.integration.title') "> </h4>
          <div class="row">
            <div class="col-12 col-sm-10 col-md-8 mx-auto">
              <p v-html=" $t('dashboard.stores.integration.message') "> </p>
              <br>
            </div>
          </div>
          <hr class="w-75">
        </div>
        <div v-else class="">
          <h5>
            <strong v-text="$t('dashboard.stores.integration.skipTitle')"></strong>
          </h5>
          <p v-html="$t('dashboard.stores.integration.skipMessage')"> </p>
          <p class=" ">
            <button class="btn btn-link" @click="setShowStoresList()"
              v-text=" showStoresList ? $t('general.button.hideStores'): $t('general.button.viewStores') ">
            </button>
          </p>
          <button class="btn btn-light w-25 " @click=" fnToPayments() " v-if=" storeList.length > 0 " :disabled="loadingComplete">
            <i class="fa fa-spin fa-spinner " v-if="loadingComplete"></i>
            <strong v-text=" $t('general.button.skip') " v-else></strong>
          </button>

          <div class="row justify-content-center" v-show="showStoresList ">
            <div class="col-12">
              <StoresList class="animated fadeIn mt-4" />
            </div>
          </div>
          <hr class="w-75 my-5">
        </div>

        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-md-5">
            <h5><span class="f-w-600" v-text="$t('messages.doYouHaveAnyEcommercee')"></span></h5>
            <h6 class="f-w-500" v-text="$t('messages.integrateStoreWhitUs')"> </h6>
            <img src="@/assets/images/gifs/ecommerces-banner.gif" alt="">
            <p>
              <button class=" btn btn-success mb-2 " @click="fnGlobalStoreIntegrationLink(false,device)">
                <strong v-text=" $t('general.button.integrateNewStore') "> </strong>
              </button>
            </p>
          </div>
          <div class="col-12 col-md-5" v-if="userSession.store_id == null">
            <h5><span class="f-w-600" v-text="$t('messages.dontHaveStoreYet')"></span></h5>
            <h6 class="f-w-500" v-text="$t('messages.createStoreWhitUs')"> </h6>
            <img class="img-cover-70 my-4" src="@/assets/images/ecart-ecommerce.png" alt="">
            <p>
              <button class=" btn btn-success mb-2 " @click="fnApiCreateStore()" :disabled="loading.createStore">
                <i class="fa fa-spinner fa-spin" v-if="loading.createStore"></i>
                <strong v-text=" $t('general.button.createStore') " v-else> </strong>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  export default {
    components: {
      StoresList: () => import('./stores-list'),
    },
    data(){
      return{
        loadingComplete: false,
      }
    },
    computed: {
      ...mapState('stores', ['storeList', 'showStoresList', 'loading']),
      ...mapState(['device', 'userSession']),
    },
    methods: {
      ...mapMutations('stores', ['setShowStoresList']),
      ...mapActions('stores', ['fnApiCreateStore']),
      ...mapMutations('session', ['setSession']),

      async fnApiUserComplete() {
        this.loadingComplete = true;
        await axios.put('me/complete').then(async response => {
          setTimeout(() => {
            this.setSession(response.data);
            window.location.replace('/dashboard/welcome');
          }, 200)
        }).catch(error => {});
        this.loadingComplete = false;
      },

      fnToPayments() {
        if( this.userSession.country != 'MX'){
          this.fnApiUserComplete();
        }else{
          this.$router.push('/dashboard/payments?integration=true');
        }
      },
    },
  }
</script>

<style>

</style>